import React from 'react'
import { graphql } from "gatsby"
import { kebabCase } from "lodash"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { SiteMetaConfig } from "gatsby-theme-0/src/config/config-data"
import loadable from "@loadable/component"
const Header = loadable(() => import("gatsby-theme-0/src/components/header"))
const Footer = loadable(() => import("gatsby-theme-0/src/components/footer"))
const Posts = loadable(() => import("./posts"))
const Pagination = loadable(() => import("./pagination"))
import "./post.css"


const TagPosts = ({ data, pageContext, location }) => {

  const { tag, currentPage, numPages } = pageContext
  const { totalCount } = data.allMarkdownRemark
  const pageHeader = `${totalCount} Post${totalCount === 1 ? '' : 's'} Tagged With "${tag}"`
  
  return (
    <>
      <GatsbySeo
        title={`${tag} Tag Page: ${currentPage} | ${SiteMetaConfig.site_name}`}
        description={SiteMetaConfig.site_description}
        canonical={`${SiteMetaConfig.site_url}${location.pathname}`}
        openGraph={{
          type: "website",
          url: `${SiteMetaConfig.site_url}${location.pathname}`,
          title: `${tag} Tag Page: ${currentPage} | ${SiteMetaConfig.site_name}`,
          description: SiteMetaConfig.site_description,
          images: [
            { url: SiteMetaConfig.site_image },
          ],
          site_name: SiteMetaConfig.site_name,
        }}
        twitter={{
          handle: `@${SiteMetaConfig.site_twitter_handle}`,
          site: `@${SiteMetaConfig.site_name}`,
          cardType: "summary_large_image",
        }}
        facebook={{
          appId: SiteMetaConfig.site_facebook_app_id,
        }}
        htmlAttributes={{
          lang: SiteMetaConfig.site_language,
        }}
      />
      <Header />
      <div id="tagposts_1662986622219" className="Page_Container">
        <div id="tagposts_1662986623523" className="Heading_Text">{pageHeader}</div>
        <div id="tagposts_1662986624490" className="Normal_Text">{`Page: ${currentPage}`}</div>
        <hr />
        <div id="tagposts_1662986625938" className="Flex_Container">
          {data.allMarkdownRemark.edges.map(({ node }) => {
            return(
              <Posts
                key={node.fields.slug}
                postsMain={node.html.replace(/<\/?[^>]+(>|$)/g, "")}
                postsSlug={node.fields.slug}
                postsBaseUrl={SiteMetaConfig.site_url}
                postsTo={node.frontmatter.to}
                postsName={node.frontmatter.name}
                postsGLTF={node.frontmatter.gltf}
                postsTags={node.frontmatter.tags}
              />
            ) 
          })}
        </div>
        <Pagination
          paginationCurrentPage={currentPage}
          paginationNumPages={numPages}
          paginationPrevTo={`/blog/tag/${kebabCase(tag)}-page-${currentPage - 1}`}
          paginationNextTo={`/blog/tag/${kebabCase(tag)}-page-${currentPage + 1}`}
        />
      </div>
      <Footer CustomBottomHeight="yes" />
    </>
  )
}

export const tagPostsQuery = graphql`
  query tagPostsQuery($skip: Int!, $limit: Int!, $tag: String!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          html
          fields {
            slug
          }
          frontmatter {
            to
            name
            gltf
            tags
          }
        }
      }
    }
  }
`

export default TagPosts